<template>
    <section class="fast-track-form">
        <header
            v-if="showHeader"
            class="fast-track_header"
        >
            <p class="fast-track_header__title">
                Place your first order with us and experience our service
            </p>
            <p>
                Fill all  information about your project and hire TOP writers or start searching best bids from all our experts.
            </p>
        </header>

        <main class="fast-track-body">
            <!-- Row -->
            <div class="fast-track-body_row bordered_row">
                <div class="fast-track-body_col fast-track-body_col--full">
                    <h2 class="fast-track-body__title">
                        Due date &amp; budget
                    </h2>
                </div>
                <!-- Deadline -->
                <div class="fast-track-body_col">
                    <div class="input_wrapper">
                        <form-date-picker
                            v-model="$v.due_at.$model"
                            label="Due date"
                            tabindex="0"
                            :min_hours="3"
                            :errors="validationMessage($v.due_at)"
                            :field.sync="due_at"
                            @change.native="$v.due_at.$touch()"
                            @blur.native="$v.due_at.$touch()"
                        />
                        <span class="custom-tooltip">
                            <p>?</p>
                            <span>
                                The date and time should be in your local timezone.<br><br>
                                Our experts may offer a different deadline (shorter or longer), after they study the project.
                            </span>
                        </span>
                    </div>
                </div>
                <!-- desired_priced budget -->
                <div class="fast-track-body_col">
                    <div class="input_wrapper">
                        <form-input
                            v-model="$v.desired_price.$model"
                            legend="Your expected or desired price in USD"
                            type="text"
                            name="Desired price (budget)"
                            :info="'What’s your approximate budget?'"
                            :field.sync="desired_price"
                            :is-valid="$v.desired_price.$dirty && !$v.desired_price.$anyError"
                            :errors="validationMessage($v.desired_price)"
                            @input.native="$v.desired_price.$touch();"
                            @blur.native="$v.desired_price.$touch();"
                        />
                        <span class="custom-tooltip">
                            <p>?</p>
                            <span>What’s your approximate budget for this project in USD?</span>
                        </span>
                    </div>
                </div>
            </div>
            <!-- Row -->
            <div class="fast-track-body_row">
                <div class="fast-track-body_col">
                    <div class="bordered_row">
                        <h2 class="fast-track-body__title">
                            Project details
                        </h2>
                        <!-- Title -->
                        <div class="input_wrapper">
                            <form-input
                                v-model="$v.title.$model"
                                legend="Title"
                                type="text"
                                name="title"
                                :info="'You can either provide a topic or have the writer come up with one'"
                                :field.sync="title"
                                :is-valid="$v.title.$dirty && !$v.title.$anyError"
                                :errors="validationMessage($v.title)"
                                @input.native="$v.title.$touch();"
                                @blur.native="$v.title.$touch();"
                            />
                        </div>
                        <!-- Instructions -->
                        <div class="input_wrapper input_wrapper--textarea">
                            <form-textarea
                                v-model="$v.details.$model"
                                legend="Detailed instructions"
                                placeholder="Provide your project requirements in detail. If you have instructions in a file, you can upload it here."
                                type="text"
                                name="instructions"
                                :field.sync="details"
                                :errors="validationMessage($v.details)"
                                @input.native="$v.details.$touch();"
                                @blur.native="$v.details.$touch();"
                            />
                            <span class="custom-tooltip">
                                <p>?</p>
                                <span>
                                    Please be specific and detailed. Include all the requirements that you think are important. While you will be able to request a free revision/rewrite after completion,
                                    we revise based on the original instructions, meaning that we will not be able to expand the paper free of charge,
                                    if you ask for new things that werent originally requested (in that case you can easily purchase extra pages)
                                </span>
                            </span>
                        </div>
                    </div>
                    <!-- Additional services -->
                    <div class="">
                        <p class="fast-track-body__title">
                            Project components
                        </p>
                        <!-- Pages -->
                        <div class="input_wrapper">
                            <form-input-type-number
                                v-model="$v.pages.$model"
                                label="Pages"
                                :field.sync="pages"
                                :show_value="true"
                                tabindex="0"
                                :min_value="1"
                                :errors="validationMessage($v.pages)"
                                @reduce="pages--"
                                @increase="pages++"
                                @update:field="$v.pages.$touch()"
                            />
                            <span class="custom-tooltip">
                                <p>?</p>
                                <span>All content is formatted as follows: 12 point Times New Roman font, 1 inch margins on all sides, double spacing, approx. 280 - 300 words per page.</span>
                            </span>
                        </div>
                        <!-- slides -->
                        <div class="fast-track-body__switch-row">
                            <form-switch
                                id="showSlides"
                                v-model="showSlides"
                            />
                            <span class="fast-track-body__switch-text">
                                Presentation slides (PowerPoint, etc.)
                            </span>
                        </div>
                        <div
                            v-if="showSlides"
                            class="input_wrapper"
                        >
                            <form-input-type-number
                                v-model="$v.slides.$model"
                                label="Slides"
                                :field.sync="slides"
                                :show_value="true"
                                tabindex="0"
                                :min_value="1"
                                :errors="validationMessage($v.slides)"
                                @reduce="slides--"
                                @increase="slides++"
                                @update:field="$v.slides.$touch()"
                            />
                        </div>
                        <!-- Charts -->
                        <div class="fast-track-body__switch-row">
                            <form-switch
                                id="showCharts"
                                v-model="showCharts"
                            />
                            <span class="fast-track-body__switch-text">
                                Charts (tables, graphs, etc.)
                            </span>
                        </div>
                        <div
                            v-if="showCharts"
                            class="input_wrapper"
                        >
                            <form-input-type-number
                                v-model="$v.charts.$model"
                                label="Charts"
                                :field.sync="charts"
                                :show_value="true"
                                tabindex="0"
                                :min_value="1"
                                :errors="validationMessage($v.charts)"
                                @reduce="charts--"
                                @increase="charts++"
                                @update:field="$v.charts.$touch()"
                            />
                        </div>
                        <!-- Problems -->
                        <div class="fast-track-body__switch-row">
                            <form-switch
                                id="showProblems"
                                v-model="showProblems"
                            />
                            <span class="fast-track-body__switch-text">
                                Quantitative problems (accounting, finance, statistics, algebra, calculus etc.)
                            </span>
                        </div>
                        <div
                            v-if="showProblems"
                            class="input_wrapper"
                        >
                            <form-input-type-number
                                v-model="$v.problems.$model"
                                label="Problems"
                                :field.sync="problems"
                                :show_value="true"
                                tabindex="0"
                                :min_value="1"
                                :errors="validationMessage($v.problems)"
                                @reduce="problems--"
                                @increase="problems++"
                                @update:field="$v.problems.$touch()"
                            />
                        </div>
                    </div>
                </div>
                <!-- Additional files -->
                <div class="fast-track-body_col">
                    <h2 class="fast-track-body__title">
                        Files, if any
                    </h2>
                    <FormFilesUploader
                        class="input_wrapper"
                        :is-uploading-in-progress="isUploadingInProgress"
                        :upload-progress="uploadProgressValue"
                        @uploadFiles="uploadFiles"
                    />
                    <div
                        v-if="additional_files.length"
                        class="input_wrapper"
                    >
                        <OrderFormFileTable
                            :files="[...additional_files]"
                            @deleteFile="deleteFile"
                        />
                    </div>
                </div>
            </div>
        </main>
        <footer class="fast-track-footer">
            <custom-button
                :loading="orderCreating"
                :loading-message="loadingMessage"
                :disabled="orderCreating"
                default
                class="btn-page-location"
                @on-btn-click="validate"
            >
                Create order
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                >
                    <path
                        d="M1 8H15M15 8L8 1M15 8L8 15"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </custom-button>
        </footer>
    </section>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { eventBus } from '@/helpers/event-bus'
import { createOrder } from '@/services/fasttrack'
import { deleteFile, uploadFilesNoAuth } from '@/services/files'

// validation
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/order/fast-track/RulesFastTrack.js'
import { formMessages } from '@/validation/order/fast-track/MessagesFastTrack.js'
import formValidationMixin from '@/mixins/formValidationMixin.js'

import { mapOrderFormFields } from '@/store/modules/order'

import OrderFormFileTable from '@/components/order/OrderFormFileTable';

import {
    CLEAR_FORM_EXPRESS
} from '@/store/modules/order/mutation-types'

const {
    mapMutations: mapOrderMutations
} = createNamespacedHelpers('order')

export default {
    name: 'FastTrackForm',
    components: {
        OrderFormFileTable
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    props: {
        showHeader: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            showCharts: false,
            showProblems: false,
            showSlides: false,
            orderCreating: false,
            loadingMessage: '',
            // Files
            isUploadingInProgress: false,
            uploadProgressValue: 0
        }
    },
    computed: {
        ...mapOrderFormFields([
            'express_form_data',
            'express_form_data.due_at',
            'express_form_data.desired_price',
            'express_form_data.service_type',
            'express_form_data.pages',
            'express_form_data.problems',
            'express_form_data.charts',
            'express_form_data.slides',
            'express_form_data.title',
            'express_form_data.details',
            'express_form_data.additional_files'
        ]),
        ...mapGetters('client', [
            'getterIsAuth'
        ])
    },
    watch: {
        showCharts(newV) {
            if (!!newV === false) {
                this.charts = null
            } else {
                this.charts = 1
            }
        },
        showProblems(newV) {
            if (!!newV === false) {
                this.problems = null
            } else {
                this.problems = 1
            }
        },
        showSlides(newV) {
            if (!!newV === false) {
                this.slides = null
            } else {
                this.slides = 1
            }
        }
    },
    validations: {
        ...validationRules
    },
    created() {
        this.showCharts = !!this.charts
        this.showProblems = !!this.problems
        this.showSlides = !!this.slides
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapOrderMutations([
            CLEAR_FORM_EXPRESS
        ]),
        async uploadFiles(filesArr) {
            try {
                this.isUploadingInProgress = true
                const files = await uploadFilesNoAuth(filesArr, null, (e) => {
                    this.uploadProgressValue = (Math.floor((e.loaded * 100) / e.total) - 1);
                })
                this.additional_files = [...this.additional_files, ...files]
                eventBus.$emit('showSnackBar', 'File was successfully uploaded', 'success');
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.isUploadingInProgress = false
            }
        },
        async deleteFile(file) {
            try {
                await deleteFile(file.file_id)
                this.additional_files = this.additional_files.filter(((item) => item.file_id !== file.file_id))
                eventBus.$emit('showSnackBar', `File "${file.filename}" has been deleted`, 'success');
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        },
        async submit() {
            this.$v.$reset()
            this.$v.$touch()
            if (this.$v.$invalid) {
                eventBus.$emit('showSnackBar', 'Please complete the form', 'error')
                return
            }
            if (!this.getterIsAuth) {
                localStorage.setItem('login_last_location', window.location.href)
                this.$router.push({ name: 'register' })
                return
            }
            try {
                this.orderCreating = true
                this.loadingMessage = 'Creating an order...'
                const orderId = await createOrder(this.express_form_data)
                this[CLEAR_FORM_EXPRESS]()
                this.$router.push({ name: 'order_details', params: { id: orderId } })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.orderCreating = false
                this.loadingMessage = ''
            }
        }
    }
}
</script>

<style lang="scss">
    .fast-track-form {
        box-shadow: 0px 8px 48px -10px rgba(16, 24, 40, 0.18);
        border-radius: 4px;
    }
    .fast-track_header{
        background: linear-gradient(94.79deg, #2E579D 0.59%, #366BC9 98.53%);
        box-shadow: 0px 0px 4px 1px rgba(16, 24, 40, 0.05);
        color: #fff;
        padding: 30px 20px;
        text-align: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        &__title{
            font-weight: 700;
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 5px;
            @media all and (max-width: 992px) {
                font-size: 22px;
            }
        }
    }
    .fast-track-body{
        display: flex;
        flex-wrap: wrap;
        padding: 20px 10px;
        &_row {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
        }
        &_col {
            width: 50%;
            padding: 0 10px;
            &--full{
                width: 100%;
            }
            @media all and (max-width: 768px) {
                width: 100%;
                padding: 0;
            }
        }
        &__title {
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 20px;
        }
        .input_wrapper{
            position: relative;
            margin-bottom: 20px;
            &--textarea {
                textarea {
                    height: 122px;
                    min-height: 122px;
                    max-height: 122px;
                }
            }
        }
        &__switch-row{
            display: flex;
            margin-bottom: 20px;
        }
        &__switch-text {
            margin-left: 10px;
        }
        .bordered_row{
            border-bottom: 1px solid #EAEAEA;
            padding-bottom: 5px;
            margin-bottom: 20px;
        }
    }
    .fast-track-files{
        padding: 0;
        margin: 0;
    }
    .fast-track-footer {
        margin-top: 20px;
        padding: 20px 0;
        border-top: 1px solid #b3bbc6;
        @media all and (max-width: 768px) {
            margin-top: 0;
            border: none;
        }
        .btn-base {
            svg {
                margin-left: 15px;
                stroke: #fff;
                transition: .3s all;
            }
            &:hover {
                svg {
                    stroke: #4a5b76;
                }
            }
        }
    }
</style>
