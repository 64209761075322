<template>
    <div class="service-addition-block">
        <div class="service-addition-block__right-side">
            <h1 class="service-addition-block__right-side-title">
                Your Friendly Paper Writing Service
            </h1>
            <span class="service-addition-block__right-side-description">Make Your Studies Effort-Free With Our Essay Service</span>
            <div class="service-addition-block__right-side-items">
                <div
                    v-for="(item, index) in serviceAdditionItems"
                    :key="index"
                    class="service-addition-block__right-side-items-container"
                >
                    <span class="service-addition-block__right-side-item-counter">{{ item.counter }}</span>
                    <span class="service-addition-block__right-side-item-text">{{ item.text }}</span>
                </div>
            </div>
        </div>
        <div class="service-addition-block__left-side">
            <img
                :src="require(`@/assets/img/service.png`)"
                class="service-addition-block__left-side-img"
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServiceBanner',
    computed: {
        serviceAdditionItems() {
            return [
                {
                    counter: '12k+',
                    text: 'Experts on the platform'
                },
                {
                    counter: '40k+',
                    text: 'Completed Orders'
                },
                {
                    counter: '98%',
                    text: 'AVG Success rate'
                },
                {
                    counter: '12k+',
                    text: 'Experts on the platform'
                }
            ]
        }
    }
};
</script>

<style scoped lang="scss">
.service-addition-block{
    display: flex;
    justify-content: center;
    max-width: 1140px;
    margin: 100px auto 100px auto;
    @include media1200max {
        flex-direction: column;
        align-items: center;
    }
    &__right-side{
        width: 55%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        margin-right: 70px;
        @include media1200max {
            width: 95%;
            margin-right: 0px;
        }
    }
    &__right-side-title {
        font-weight: 700;
        font-size: 46px;
        line-height: 53px;
        @include media1200max {
            text-align: center;
        }
    }
    &__right-side-description {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        margin: 25px 0 20px 0;
        @include media1200max {
            text-align: center;
        }
    }
    &__right-side-items {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        @include media1200max {
            margin-bottom: 40px;
            gap: 15px;
        }
    }
    &__right-side-items-container{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 20px;
        gap: 2px;
        //width: 285px;
        width: 48%;
        height: 109px;
        background: #FFFFFF;
        box-shadow: 0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 2px 6px -2px rgba(16, 24, 40, 0.03);
        border-radius: 4px;
        @include media576max {
            width: 100%;
        }
    }
    &__right-side-item-counter{
        font-weight: 700;
        font-size: 32px;
        line-height: 45px;
    }
    &__right-side-item-text{
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }
    &__left-side{
        width: 45%;
        @include media1200max {
            width: 50%;
            display: flex;
            justify-content: center;
        }
    }
    &__left-side-img{
        width: 520px;
        height: 520px;
        border-radius: 4px;
        @include media576max {
            width: 360px;
            height: 360px;
            padding: 20px;
        }
    }
}
</style>
