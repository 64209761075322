<template lang="html">
    <div
        v-if="!configLoading"
        class="express-order"
    >
        <div class="wrapper">
            <div
                v-if="getterIsAuth"
                class="express-order_row"
            >
                <AccountTopBar />
            </div>

            <FastTrackForm :show-header="showOrderFormHeader" />

            <div class="express-order_row">
                <TopWritersSlider />
            </div>

            <div class="express-order_row express-order_row--flex">
                <div class="express-order_col-9">
                    <FaqList />

                    <div class="express-order_support-button">
                        <router-link
                            :to="{name: 'support_form'}"
                            class="btn-base btn-border"
                        >
                            Create support ticket
                        </router-link>
                    </div>
                </div>
                <div class="express-order_col-3">
                    <support-banner class="express-order_banner" />
                </div>
            </div>
        </div>
        <div class="express-order_row">
            <our-guarantees />
        </div>
    </div>
</template>

<script>
import FastTrackForm from '@/components/order/FastTrackForm.vue';
import AccountTopBar from '@/components/AccountTopBar';
import SupportBanner from '@/components/common/banners/SupportBanner.vue'
import OurGuarantees from '@/components/common/OurGuarantees.vue'

import { createNamespacedHelpers, mapGetters } from 'vuex'
import {
    GET_PRICING
} from '@/store/modules/config/action-types'
import {
    ACTION_UPDATE_CLIENT_DATA
} from '@/store/modules/client/action-types'
import TopWritersSlider from '@/components/topWritersSlider/TopWritersSlider';
import FaqList from '@/components/FAQ/FaqList';

const {
    mapActions: mapConfigActions
} = createNamespacedHelpers('config')
const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client')
export default {
    name: 'OrderFastTrack',
    components: {
        AccountTopBar,
        FastTrackForm,
        TopWritersSlider,
        FaqList,
        SupportBanner,
        OurGuarantees
    },
    data() {
        return {
            configLoading: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterIsAuth',
            'getterMainClientInfo'
        ]),
        showOrderFormHeader() {
            return (this.getterIsAuth && this.getterMainClientInfo.order_total === 0) || !this.getterIsAuth
        }
    },
    async created() {
        this.configLoading = true
        const requests = [this[GET_PRICING]()]
        if (this.getterIsAuth) {
            requests.push(this[ACTION_UPDATE_CLIENT_DATA]())
        }
        await Promise.all(requests)
        this.configLoading = false
    },
    methods: {
        ...mapConfigActions([
            GET_PRICING
        ]),
        ...mapClientActions([
            ACTION_UPDATE_CLIENT_DATA
        ])
    }
}
</script>

<style lang="scss">
    .express-order {
        &_row {
            margin: 50px 0;
            &--flex {
                display: flex;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        &_col-9{
            width: 75%;
            padding-right: 20px;
            @media all and (max-width: 992px) {
                width: 100%;
                padding-right: 0;
            }
        }
        &_col-3{
            padding-left: 20px;
            width: 25%;
            @media all and (max-width: 992px) {
                display: none;
            }
        }
        &_banner{
            position: sticky;
            top: 66px;
        }
        &_support-button{
            display: flex;
            justify-content: center;
            padding-top: 24px;
        }
    }
</style>
