<template>
    <div
        class="faq_item"
        @click="isOpen=!isOpen"
    >
        <div
            class="faq_item__question"
        >
            <div class="faq_item__index">
                {{ getIndex }}
            </div>
            {{ faq.question }}
            <div
                class="faq_item__btn"
                :class="{'faq_item__btn-active': isOpen}"
            >
                <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                >
                    <path
                        d="M1 1L7 7L13 1"
                        stroke="#111720"
                        stroke-width="2"
                    />
                </svg>
            </div>
        </div>
        <transition name="slide">
            <div
                v-if="isOpen"
                class="faq_item__answer"
            >
                <br>
                {{ faq.answer }}
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        index: {
            type: Number,
            required: true
        },
        faq: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isOpen: false
        }
    },
    computed: {
        getIndex() {
            let { index } = this
            index += 1
            if (index < 10) {
                return `0${index}`
            }
            return index
        }
    }
}
</script>

<style lang="scss" scoped>
    .slide-enter-active {
        transition-duration: 0.3s;
        transition-timing-function: ease-in;
    }
    .slide-leave-active {
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    .slide-enter-to, .slide-leave {
        max-height: 200px;
        overflow: hidden;
    }
</style>
