<template>
    <div>
        <div class="order_way_list">
            <div
                v-for="(way, index) in orderWays"
                :key="index"
                class="order_way_list__item"
            >
                <span
                    class="order_way_list__item-label"
                    :class="`order_way_list__item-label--${way.type}`"
                >
                    {{ way.label }}
                </span>
                <h1 class="order_way_list__item-title">
                    {{ way.title }}
                </h1>
                <div class="order_way_list__item-body">
                    <p class="order_way_list__item-description">
                        {{ way.description }}
                    </p>
                    <div
                        v-for="(bullet, bulletIndex) in way.bullets"
                        :key="bulletIndex"
                        class="order_way_list__item-subtitle-wrapper"
                    >
                        <div class="order_way_list__item-subtitle-wrapper-icon">
                            <component
                                :is="way.icon"
                            />
                        </div>
                        <p
                            class="order_way_list__item-description"
                            v-html="bullet.subtitle"
                        />
                    </div>
                </div>
                <router-link
                    :to="{name: way.route_name}"
                    class="order_way_list__item-btn"
                >
                    {{ way.btn }}
                </router-link>
            </div>
        </div>
        <service-banner />
    </div>
</template>

<script>
import { getCheckoutWays } from '@/services/settings'
import Check from '@/components/icons/Check'
import ServiceBanner from '@/components/common/ServiceBanner.vue';

export default {
    components: {
        ServiceBanner,
        Check
    },
    computed: {
        orderWays() {
            return getCheckoutWays()
        }
    },
    created() {
        if (this.orderWays.length === 0) {
            this.$router.push({ name: 'order_new' })
        }
        if (this.orderWays.length === 1) {
            this.$router.push({ name: this.orderWays[0].route_name })
        }
    }
}
</script>

<style lang="scss">
    .order_way_list{
        display: flex;
        justify-content: center;
        flex-direction: initial;
        margin-top: -55px;
        @include media768max {
            flex-wrap: wrap;
        }
        &__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 30px;
            gap: 20px;
            width: 387px;
            background: #FFFFFF;
            box-shadow: 0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 2px 6px -2px rgba(16, 24, 40, 0.03);
            border-radius: 4px;
            align-self: stretch;
            &:first-child{
                margin-right: 20px;
                @include media768max {
                    margin-right: 0px;
                    margin-bottom: 20px;
                }
            }
        }
        &__item-label {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
        }
        &__item-label--express{
            background: #FFEEE6;
            border-radius: 4px;
            color: #FF690C;
            padding: 2px 6px;
        }
        &__item-label--standard{
            background: #F2FBF2;
            border-radius: 4px;
            color: #1FB119;
            padding: 2px 6px;
        }
        &__item-title{
            font-weight: 700;
            font-size: 32px;
            line-height: 45px;
            white-space: nowrap;
        }
        &__item-body{
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 15px 0;
            border-bottom: 1.5px solid #EAEAEA;
            border-top: 1.5px solid #EAEAEA;
        }
        &__item-description {
            font-weight: 400;
            line-height: 22px;
        }
        &__item-subtitle-wrapper{
            display: flex;
            align-items: baseline;
        }
        &__item-subtitle-wrapper-icon{
            width: 20px;
            margin-right: 15px;
        }
        &__item-btn {
            background: #1F2939;
            color: $white;
            padding: 6px 12px;
            width: 100%;
            height: 48px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            white-space: nowrap;
            margin-right: 15px;
            font-weight: 800;
            font-size: 14px;
            justify-content: center;
            text-transform: uppercase;
            &:hover {
                color: $main-color;
                background: rgba($main-color, 0.1);
            }
        }
    }

    .writer-service-bg{
        background: #F2F6FF;
        padding-bottom: 80px !important;
        .wrapper .breadcrumbs-wrapper .breadcrumbs-info{
            display: flex;
            flex-direction: column;
            align-items: center;
            .breadcrumbs-item{
                width: initial;
                text-align: center;
            }
        }
    }
</style>
