<template>
    <div class="faq">
        <div class="faq_header">
            <div class="faq_title">
                FAQ
            </div>
        </div>
        <div class="faq_list">
            <FaqItem
                v-for="(item, index) in faqList"
                :key="index"
                :faq="item"
                :index="index"
            />
        </div>
    </div>
</template>

<script>
import FaqItem from '@/components/FAQ/FaqItem';

export default {
    components: {
        FaqItem
    },
    computed: {
        faqList() {
            return [
                {
                    question: 'Who is this service meant for?',
                    answer: `Our writers help the people like you who are stuck with a written assignment.
                    Anyone can experience a writer's block or simply have no time to handle a project.
                    That's when our writers can save the day.`
                },
                {
                    question: 'Is this service legal? Isn\'t this cheating?',
                    answer: `The services of a ghostwriter are not prohibited by any law.
                    In fact 99% of celebrities and politicians hire a ghostwriter to write books and mémoire.`
                },
                {
                    question: 'Is your service absolutely confidential?',
                    answer: `Using our service is totally confidential. We do not disclose information about our clients to any third party.
                    All your contact information and the order related details are protected by our stringent confidentiality agreement.
                    Our services are 100% secure and confidential.`
                },
                {
                    question: 'How competent are your writers?',
                    answer: `Our writers have passed our rigorous screening and have since completed hundreds
                    of successful assignments.`
                },
                {
                    question: 'Do you meet deadlines?',
                    answer: `Yes. That is our promise to you. We will refund your money,
                    if the deadline is not met.`
                },
                {
                    question: 'Will you follow my directions?',
                    answer: `We have real writers who take your assignment, study the requirements and do the research.
                    Then, they type the paper on a computer and send it to you for a review. If something wasn't followed, we will revised free of charge.`
                }
            ]
        }
    }
}
</script>

<style lang="scss">
    .faq{
        &_header{
            display: flex;
            margin-bottom: 30px;
        }
        &_title {
            font-weight: 700;
            font-size: 26px;
            line-height: 36px;
            color: #111720;
        }
        &_list {
            padding-bottom: 24px;
            border-bottom: 1px solid #EAEAEA;
            .faq_item {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &_item{
            box-shadow: 0px 4px 16px -4px rgba(16, 24, 40, 0.08), 0px 2px 6px -2px rgba(16, 24, 40, 0.03);
            border-radius: 4px;
            margin: 20px 0;
            padding: 20px;
            background: #fff;
            &__question{
                display: flex;
                align-items: center;
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: #1F2939;
                cursor: pointer;
            }
            &__index{
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: #FF5404;
                margin-right: 15px;
            }
            &__answer{
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #1F2939;
            }
            &__btn {
                margin-left: auto;
                transition: transform 0.2s ease-in;
                &-active {
                    transform: rotate(180deg);
                }
            }
        }
    }
</style>
