<template>
    <div class="overlay">
        <div
            v-click-outside="close"
            class="modal_top-up"
        >
            <header>
                <p>Top up your current balance</p>
                <span
                    class="modal_top-up__close"
                    @click="close()"
                ><CloseIcon /></span>
            </header>
            <div class="modal_top-up_body">
                <form-input-type-number
                    v-model="$v.amount.$model"
                    label="Amount"
                    :field.sync="amount"
                    :show_value="true"
                    :with_text="true"
                    :text="'$'"
                    tabindex="0"
                    :min_value="1"
                    :errors="validationMessage($v.amount)"
                    @reduce="amount--"
                    @increase="amount++"
                    @update:field="$v.amount.$touch()"
                />
                <div
                    class="modal_top-up__presets-list"
                >
                    <button
                        v-for="preset in presets"
                        :key="preset.amount"
                        class="btn-base modal_top-up__presets-item"
                        @click="amount = preset.amount"
                    >
                        <span class="amount">
                            ${{ preset.amount }}
                        </span>
                        <span class="label">
                            {{ preset.label || '' }}
                        </span>
                    </button>
                </div>
                <div class="modal_top-up__note">
                    By clicking "TOP UP" button you agree to the <a
                        href="/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                    >Terms and Conditions</a> and <a
                        href="/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >Privacy Policy</a>.
                </div>
            </div>
            <div class="modal_top-up_footer">
                <button
                    class="confirm-modal__two-button confirm-modal__two-button--gray"
                    @click="close()"
                >
                    Cancel
                </button>
                <button
                    class="confirm-modal__two-button confirm-modal__two-button--main"
                    @click="submit"
                >
                    Top up
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Api from '@/helpers/api/index.js';
import { eventBus } from '@/helpers/event-bus';
// validation
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { validationRules } from '@/validation/topUp/RulesTopUp.js'
import { formMessages } from '@/validation/topUp/MessagesTopUp.js'
import CloseIcon from 'mdi-vue/Close.vue';

export default {
    name: 'TopUpModal',
    components: {
        CloseIcon
    },
    validations: {
        ...validationRules
    },
    mixins: [
        validationMixin
    ],
    data() {
        return {
            amount: 1,
            presets: [
                {
                    amount: 100
                },
                {
                    amount: 500,
                    label: 'and get +3%'
                },
                {
                    amount: 1000,
                    label: 'and get +5%'
                }
            ]
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        close() {
            this.$emit('closeTopUp')
        },
        async submit() {
            try {
                const { data } = await Api.post('/api/order-batch/top-up-balance', { amount: this.amount * 100 })
                this.$router.push({ name: 'checkout-batch', params: { batchid: data.batchid } })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        }
    }
}
</script>

<style lang="scss">
    .overlay{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99;
        background: rgba(0,0,0,0.2);
        .modal_top-up{
            width: 100%;
            max-width: 500px;
            background: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            border-radius: 4px;
            header {
                font-size: 22px;
                font-weight: 700;
                padding: 20px 30px;
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media (max-width: 576px) {
                    padding: 20px 10px;
                }
                &:after{
                    content: '';
                    position: absolute;
                    left: 20px;
                    right: 20px;
                    bottom: 0;
                    height: 1px;
                    background: rgba(0,0,0,0.2);
                    @media (max-width: 576px) {
                        left: 10px;
                        right: 10px;
                    }
                }
            }
            &_body{
                padding: 30px 20px 10px;
                position: relative;
                @media (max-width: 576px) {
                    padding: 30px 10px 10px;
                }
                &:after{
                    content: '';
                    position: absolute;
                    left: 20px;
                    right: 20px;
                    bottom: 0;
                    height: 1px;
                    background: rgba(0,0,0,0.2);
                    @media (max-width: 576px) {
                        left: 10px;
                        right: 10px;
                    }
                }
            }
            &_footer {
                padding: 20px 20px;
                display: flex;
                justify-content: space-between;
                @media (max-width: 576px) {
                    padding: 20px 10px;
                }
            }
            &__close{
                cursor: pointer;
            }
            &__presets {
                &-list {
                    margin: 30px 0;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    gap: 10px;
                }
                &-item {
                    position: relative;
                    min-width: 130px;
                    display: flex;
                    flex-direction: column;
                    border: 2px solid #d53a9d;
                    border-radius: 50px;
                    background: #fff;
                    transition: all .2s;
                    overflow: hidden;
                    &::before {
                        content: "";
                        position: absolute;
                        top: -5px;
                        bottom: -5px;
                        left: -20px;
                        width: 15px;
                        transform: skew(150deg, 190deg);
                        background: rgba($color: #d53a9d, $alpha: .25);
                    }
                    &:hover {
                        background: rgba($color: #d53a9d, $alpha: .1);
                        &::before {
                            animation: blinck .2s ease-in-out 0s 1 normal backwards;
                        }
                    }
                    .label {
                        font-size: 10px;
                        color: $error-color;
                        text-transform: lowercase;
                    }
                }
            }
            &__note {
                font-size: 11px;
                a {
                    color: $main-color;
                }
            }
        }
    }
    @keyframes blinck {
        from {
            left: -20px;
        }
        to {
            left: calc(100% + 20px);
        }
    }
</style>
